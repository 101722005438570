import React from "react";

function Icon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 490.544 490.544"
      version="1.1"
      viewBox="0 0 490.544 490.544"
      xmlSpace="preserve"
      {...props}
    >
      <g fill="#607D8B">
        <path d="M18.098 274.151L245.213 47.015l227.115 227.136c4.237 4.093 10.99 3.975 15.083-.262a10.666 10.666 0 000-14.821L252.744 24.401c-4.165-4.164-10.917-4.164-15.083 0L2.994 259.068c-4.093 4.237-3.975 10.99.262 15.083 4.134 3.992 10.687 3.992 14.82 0h.022z"></path>
        <path d="M252.765 216.38c-4.165-4.164-10.917-4.164-15.083 0L3.016 451.047c-4.093 4.237-3.976 10.99.262 15.083a10.666 10.666 0 0014.821 0l227.115-227.115 227.115 227.136c4.237 4.093 10.99 3.976 15.083-.261 3.993-4.134 3.993-10.688 0-14.821L252.765 216.38z"></path>
      </g>
      <path
        fill="currentColor"
        d="M479.88 277.266a10.663 10.663 0 01-7.552-3.115L245.213 47.015 18.098 274.151c-4.237 4.093-10.99 3.975-15.083-.262-3.992-4.134-3.992-10.687 0-14.82L237.682 24.401c4.165-4.164 10.917-4.164 15.083 0l234.667 234.667c4.159 4.172 4.148 10.926-.024 15.085a10.667 10.667 0 01-7.528 3.113z"
      ></path>
      <path
        fill="currentColor"
        d="M479.88 469.266a10.663 10.663 0 01-7.552-3.115L245.213 239.015 18.098 466.151c-4.237 4.093-10.99 3.976-15.083-.262a10.666 10.666 0 010-14.821l234.667-234.667c4.165-4.164 10.917-4.164 15.083 0l234.667 234.667c4.159 4.172 4.148 10.926-.024 15.085a10.667 10.667 0 01-7.528 3.113z"
      ></path>
    </svg>
  );
}

export default Icon;
