import React from "react";

function Icon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 537.6 537.6"
      version="1.1"
      viewBox="0 0 537.6 537.6"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="currentColor"
        d="M173.2 314.4c21.3 3.199 47.3-1.701 75.7-3.4 5.3 1.199 10.6 2 16.1 2 5.6 0 11.1-.801 16.6-2.1 73.9 3.6 131.5 24.5 131.5-125.9C413.1 82.9 346.8 0 265 0S116.9 82.9 116.9 185c0 82.1 15.7 114 41.2 125.1 4.7 2.2 9.8 3.5 15.1 4.3zm-23.6-187.1c15.5 21.5 55.7 16.9 90.4-10.3 8.8-6.9 16.5-14.8 22.7-22.8.8.1 1.6.2 2.4.4 3.4.6 6.5 1.8 10 2.9 3.101 1.5 6.5 2.7 9.5 4.6 3.101 1.6 5.9 3.8 8.7 5.7 2.5 2.2 5.3 4.3 7.4 6.7 2.199 2.2 4.3 4.6 5.899 6.9 1.8 2.2 3.101 4.5 4.4 6.5s2.399 3.8 3 5.4c1.5 3.1 2.399 4.9 2.399 4.9s-.3-1.9-.899-5.4c-.2-1.8-.7-3.8-1.3-6.2-.601-2.4-1.301-5.2-2.5-8-1-2.9-2.4-5.9-4-9.1-1.5-3.2-3.7-6.3-5.801-9.7-2.5-3.1-4.8-6.5-7.8-9.5-2.8-3.2-6.2-5.9-9.5-8.8-3.1-2.2-6.399-4.6-9.899-6.5 1.5-3.1 2.699-6.3 3.699-9.3 12.4 5.6 25.101 12.7 35.2 22.8 37.101 37.1 22.5 80.2 51.101 51.7 4.5-4.5 8.199-9.2 11.1-13.9 1 0 2-.1 2.8.1 3.9 1 6.4 4 7.8 6.4 3.9 6.5 4.801 15.9 2.4 25.3-3.6 14-12.8 22.3-20.2 23.2l-6 .8L361 188c-14.2 51.5-52.5 106.3-95.8 106.3s-81.5-54.8-95.8-106.3l-1.6-5.9-6-.8c-7.3-1-16.6-9.3-20.2-23.2-2.4-9.4-1.5-18.8 2.4-25.3.9-1.8 2.9-4.1 5.6-5.5zM376.3 327.9c-11.6 19.799-25.1 36.5-42.9 45.699-36.8 18.8-64.199 34.2-64.199 34.2l-.101-.1v-.301l-.3.2-.3-.2v.301l-.1.1s-27.3-15.2-64.2-34.2c-17.8-9.199-31.2-25.9-42.9-45.699-54.5 22.199-92.5 72.999-92.5 117.499v92.2h400v-92.2c0-44.399-38.1-95.299-92.5-117.499z"
      ></path>
    </svg>
  );
}

export default Icon;
