import React from "react";

function SvgComponent(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 490.688 490.688"
      version="1.1"
      viewBox="0 0 490.688 490.688"
      xmlSpace="preserve"
      {...props}
    >
      <g fill="#607D8B">
        <path d="M472.328 216.529L245.213 443.665 18.098 216.529c-4.237-4.093-10.99-3.975-15.083.262-3.992 4.134-3.992 10.687 0 14.82l234.667 234.667c4.165 4.164 10.917 4.164 15.083 0l234.667-234.667c4.093-4.237 3.975-10.99-.262-15.083a10.666 10.666 0 00-14.821 0l-.021.001z"></path>
        <path d="M472.328 24.529L245.213 251.665 18.098 24.529A10.668 10.668 0 003.015 39.612l234.667 234.667c4.165 4.164 10.917 4.164 15.083 0L487.432 39.612c4.237-4.093 4.354-10.845.262-15.083-4.093-4.237-10.845-4.354-15.083-.262-.089.086-.176.173-.262.262h-.021z"></path>
      </g>
      <path
        fill="currentColor"
        d="M245.213 469.415a10.663 10.663 0 01-7.552-3.115L2.994 231.633c-4.093-4.237-3.975-10.99.262-15.083 4.134-3.992 10.687-3.992 14.82 0l227.136 227.115 227.115-227.136c4.237-4.093 10.99-3.975 15.083.262a10.666 10.666 0 010 14.821L252.744 466.279a10.674 10.674 0 01-7.531 3.136z"
      ></path>
      <path
        fill="currentColor"
        d="M245.213 277.415a10.663 10.663 0 01-7.552-3.115L2.994 39.633A10.668 10.668 0 0118.077 24.55l227.136 227.115L472.328 24.529c4.093-4.237 10.845-4.354 15.083-.262s4.354 10.845.262 15.083c-.086.089-.173.176-.262.262L252.744 274.279a10.674 10.674 0 01-7.531 3.136z"
      ></path>
    </svg>
  );
}

export default SvgComponent;
