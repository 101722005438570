import React from "react";

function Icon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 510 510" // 510
      version="1.1"
      viewBox="0 0 510 510"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="currentColor"
        d="M2321 5110c-766-75-1462-491-1893-1135-156-233-302-572-362-840-49-219-60-324-60-575 0-250 11-355 60-575 73-328 242-689 452-967C927 477 1518 130 2210 23c161-24 539-24 700 1 452 69 840 231 1197 497 126 94 398 366 492 492 266 357 428 745 497 1197 25 161 25 539 1 700-82 530-304 1001-646 1373-406 440-919 715-1508 807-148 24-475 34-622 20zm641-1017c185-88 264-290 183-471-30-65-113-148-180-178-195-89-440-11-533 171-37 73-43 197-11 280 28 75 114 165 192 201 108 49 241 48 349-3zm-387-1037c234-50 359-219 340-460-11-135-23-189-115-518-118-416-133-483-134-598-2-145 29-183 163-200 72-9 166 2 249 31 30 10 56 19 57 19 10 0-44-174-56-180-37-21-340-129-394-141-76-16-262-16-340 1-92 19-164 58-231 125-87 86-108 144-108 295 1 143 13 205 120 583 110 391 127 463 128 572 1 104-16 145-74 180-30 17-51 20-140 19-87 0-118-5-178-27l-72-26 5 22c3 12 13 53 23 91l17 68 55 22c110 45 303 108 368 121 87 17 241 18 317 1z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
}

export default Icon;
